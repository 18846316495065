/* Provide sufficient contrast against white background */

body {
    background-color: #1e1e1e;
    border-color: #FEDC97;
}

.btn-about-us {
    color: #1E1E1E;
    background-color: #D8A0DF;
    border-radius: 50px;
    margin-top: 1%;
    margin-right: 58%;
    margin-left: 47%;
    padding-right: 10px;
    padding-left: 10px;
    width: max-content;
}

.center-me {
    margin: 0 auto;
    text-align: center;
    position: relative;
}
.center-child {
    left: 50%;
    top: 50%;
    justify-content:center;
}

.paddingMain {
    padding:10%;
    vertical-align:middle;
}
@media (max-width: 500px) {
    .paddingMain {
        padding: 10%;
        vertical-align: middle;
    }
}

    img.scaleMainLogo {
        width: 100%;
        height: auto;
    }

    @media (max-width: 500px) {
        img.scaleMainLogo {
            width: 80%;
            height: auto;
        }
    }

    img.scaleSocMedLogo {
        width: 40px;
        height: auto;
        margin:10px;
    }

    @media (max-width: 500px) {
        img.scaleSocMedLogo {
            width: 7vw;
            height: auto;
            margin: 5px;
        }
    }

img.scaleAboutus {
    width: 200px;
    height: auto;
}

@media (max-width: 500px) {
    img.scaleAboutus {
        width: 40%;
        height: auto;
    }
}
.container h1 {
    color: black;
    text-align: center;
    font-family: "Roboto", sans-serif;
}

details {
    background-color: #2F2123;
    color: #fff;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    margin: 10px;
    text-align:left;
}

summary {
    padding: .5em 1.3rem;
    display: flex;
    justify-content: space-between;
    transition: height 1s ease;
  
}

    summary:after {
        
    }

details[open] summary {
    border-bottom: 1px solid #fff;
    margin-bottom: .5em;
}

    details[open] summary:after {
        
    }

details[open] div {
    padding: .5em 1em;
    background-color: white;
    color: black;
}

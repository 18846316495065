.paddingOurWork {
    padding: 10%;
    vertical-align: middle;
}

@media (max-width: 500px) {
    .paddingOurWork {
        padding: 10%;
        vertical-align: middle;
    }
}

img.scaleWorkLogo {
    width: 100%;
    height: auto;
}

@media (max-width: 500px) {
    img.scaleWorkLogo {
        width: 80%;
        height: auto;
    }
}

.whiteBackground {
    margin: 30px;
    background-color: white;
    padding: 10px;
    border-radius: 20px;
}
img.scaleOurWorkImage {
    max-width: 50%;
    min-width: 30%;
    height: auto;
}

.paddingOurWorkDetail {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    text-align: center;
}

a.ourWorkLink {
    color: white;
    font-family: "Roboto", sans-serif;
}

.paddingOurWorkScreenShort {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 20px;
    text-align: center;
    margin-top:30px;
    background-color: #4A3536;
}

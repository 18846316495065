a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(74, 53, 54)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler {
    border: 2px solid rgba(74, 53, 54);
}
.barlogo {
    width: 35px;
    height: auto;
}
.barButton {
    color: #ffffff;
}
.box-shadow {
    box-shadow: 0 .25rem .25rem rgb(255, 0, 0);
    border: none !important;
    outline: none !important;
}
.bar-title {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
}
.home-link {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
}
.about-link {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
}
.pp-link {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
}

/* Provide sufficient contrast against white background */

a {
    color: #E01A76;
}

@media screen and (max-width: 767px) {
    h3.featured-grid--item--title {
        font-size: 12px !important;
    }
}

h1 {
    font-size: 25vw;
}

code {
    color: #E01A76;
}
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
}
.section-full {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    height: 100px;
    left: 50%;
}

#tableWithoutHeader {
    font-family: "Roboto", sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: white;
    text-align: start;
    margin-top:20px;
}

    #tableWithoutHeader td, #tableWithoutHeader th {
        border: 1px solid black;
        padding: 8px;
    }

    #tableWithoutHeader tr:nth-child(odd) {
        background-color: #4A3536;
        color: white;
        font-family: "Roboto", sans-serif;
    }
    #tableWithoutHeader tr:nth-child(even) {
        background-color: white;
        color: black;
        font-family: "Roboto", sans-serif;
    }

    #tableWithoutHeader tr:hover {
        background-color: #ddd
    }

#tableWithoutHeaderDark {
    font-family: "Roboto", sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: white;
    text-align: start;
    margin-top: 20px;
}

    #tableWithoutHeaderDark td, #tableWithoutHeaderDark th {
        border: 1px solid black;
        padding: 8px;
    }

    #tableWithoutHeaderDark tr:nth-child(odd) {
        background-color: #2F2123;
        color: white;
        font-family: "Roboto", sans-serif;
    }

    #tableWithoutHeaderDark tr:nth-child(even) {
        background-color: white;
        color: black;
        font-family: "Roboto", sans-serif;
    }

    #tableWithoutHeaderDark tr:hover {
        background-color: #ddd
    }


.centerTable {
    margin-left: auto;
    margin-right: auto;
}
